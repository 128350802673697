import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { recalibrateGroupDrive } from "../apis/groupsApis";
import { getUserAccessToken } from "../apis/userApis";
import ThemeButton from "../components/themeButton";
import GoogleDrivePicker from "../components/googleDrivePicker";

const RecalibrateDriveModal = ({ setShow, show, onHide, groupId, driveId }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDrive, setSelectedDrive] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserAccessToken(userFromStorage.email);
      } else {
        onHide();
      }
    }
  }, [show, onHide]);

  const fetchUserAccessToken = async (email) => {
    try {
      const response = await getUserAccessToken(email);
      if (response.status >= 200 && response.status < 300) {
        setAccessToken(response.data.accessToken);
      } else {
        onHide();
      }
    } catch (error) {
      console.error(error);
      onHide();
    }
  };

  const handleDriveSelect = (drive) => {
    setSelectedDrive(drive);
  };

  const recalibrateDrive = async () => {
    if (!selectedDrive) {
      alert("Please select a drive");
      return;
    }

    setLoading(true);
    const response = await recalibrateGroupDrive(groupId);

    if (response.status !== 200) {
      alert("Failed to recalibrate drive");
      setLoading(false);
      return;
    }

    setLoading(false);
    alert("Drive recalibrated successfully");
    resetState();
    onHide();
  };

  const resetState = () => {
    setSelectedDrive(null);
    setLoading(false);
  };

  const handleHide = () => {
    if (!loading) {
      resetState();
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="lg"
      centered
      backdrop={loading ? "static" : true}
      keyboard={!loading}
    >
      <Modal.Header closeButton={!loading}>
        <Modal.Title>
          Recalibrate Drive
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <>
            <p>Recalibrating drive...</p>
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </>
        ) : (
          <>
            <p>Select the Shared Drive you want to recalibrate:</p>
            <div className="mb-3" style={{ zIndex: "11000" }}>
              <GoogleDrivePicker
                onSelect={handleDriveSelect}
                disabled={loading}
                accessToken={accessToken}
                modalSetShow={setShow}
                recalibrate={true}
                driveId={driveId}
              />
              {selectedDrive && (
                <div className="mt-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  Access Given To: <strong>{selectedDrive.map(drive => drive.name).join(",")}</strong>
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton
          variant="outline-primary"
          onClick={recalibrateDrive}
          disabled={loading || !selectedDrive}
        >
          Recalibrate Drive
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default RecalibrateDriveModal;