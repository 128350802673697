import React, { useEffect, useState } from "react";
import { Modal, Spinner, Form } from "react-bootstrap";
import { importGroupDrive, getGroupById } from "../apis/groupsApis";
import { getUserByEmail, getUserAccessToken } from "../apis/userApis";
import ThemeButton from "../components/themeButton";
import { FaAngleUp, FaArrowRight } from "react-icons/fa6";
import GoogleDrivePicker from "../components/googleDrivePicker";

const ImportDriveToGroupModal = ({ setShow, show, onHide, setAssignDriveResponse, groupId }) => {
  const [step, setStep] = useState(1);
  const [profile, setProfile] = useState({});
  const [group, setGroup] = useState({});
  const [selectedRootDrive, setSelectedRootDrive] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [assigningDrive, setAssigningDrive] = useState(false);
  const [importOptions, setImportOptions] = useState({
    people: true,
    folders: true,
    files: true
  });
  const [accessToken, setAccessToken] = useState(null);

  const fetchUserProfile = (email) => {
    getUserByEmail(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
          fetchUserAccessToken(email);
        } else {
          setAssignDriveResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        setAssignDriveResponse(503);
        resetState();
        onHide();
      });
  };

  const fetchGroup = async (groupId) => {
    try {
      getGroupById(groupId).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setGroup(response.data.group);
        } else {
          setAssignDriveResponse(response.status);
          resetState();
          onHide();
        }
      });
    } catch (error) {
      console.error(error);
      setAssignDriveResponse(503);
      resetState();
      onHide();
    }
  };

  const fetchUserAccessToken = async (email) => {
    try {
      const response = await getUserAccessToken(email);
      if (response.status >= 200 && response.status < 300) {
        setAccessToken(response.data.accessToken);
      } else {
        setAssignDriveResponse(response.status);
        resetState();
        onHide();
      }
    } catch (error) {
      console.error(error);
      setAssignDriveResponse(503);
      resetState();
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        fetchGroup(groupId);
        setAssignDriveResponse(200);
      } else {
        setAssignDriveResponse(401);
        onHide();
      }
    }
  }, [show, onHide, setAssignDriveResponse, groupId]);

  const resetState = () => {
    setProfile({});
    setGroup({});
    setAssigningDrive(false);
    setSelectedRootDrive(null);
    setSelectedItems(null);
    setStep(1);
    setImportOptions({
      people: true,
      folders: true,
      files: true
    });
  };

  const handleRootDriveSelect = (drive) => {
    setSelectedRootDrive(drive);
  };

  const handleItemsSelect = (items) => {
    setSelectedItems(items);
  };

  const handleNextStep = () => {
    if (selectedRootDrive) {
      setStep(2);
    }
  };

  const handleAssignDrive = async () => {
    setAssigningDrive(true);
    if (!selectedItems || !selectedRootDrive) {
      alert("Please select items to import");
      setAssigningDrive(false);
      return;
    }

    const createGroupDriveResponse = await importGroupDrive(
      selectedRootDrive.id,
      group.id,
      importOptions.people,
      importOptions.folders,
      importOptions.files
    );

    if (createGroupDriveResponse.status >= 200 && createGroupDriveResponse.status < 300) {
      alert("Drive assigned successfully.");
      setAssignDriveResponse(createGroupDriveResponse.status);
      resetState();
      onHide();
    } else {
      let msg = await JSON.stringify(createGroupDriveResponse.data.message);
      alert("Failed to assign Drive. Error: " + msg);
      setAssignDriveResponse(createGroupDriveResponse.status);
      resetState();
      onHide();
    }
  };

  const handleHide = () => {
    if (!assigningDrive) {
      resetState();
      onHide();
    }
  };

  const handleImportOptionChange = (option) => {
    setImportOptions(prev => {
      const newOptions = { ...prev };
      if (option === "folders") {
        if (!prev.folders) {
          newOptions.folders = true;
        } else {
          newOptions.folders = false;
          newOptions.files = false;
        }
      } else if (option === "files") {
        if (!prev.files) {
          newOptions.files = true;
          newOptions.folders = true;
        } else {
          newOptions.files = false;
        }
      } else {
        newOptions[option] = !prev[option];
      }
      return newOptions;
    });
  };

  const renderStep1 = () => (
    <>
      <p>Select root folder for <strong>{group.name}</strong></p>
      <div className="mb-3" style={{ zIndex: "11000" }}>
        <GoogleDrivePicker
          onSelect={handleRootDriveSelect}
          disabled={assigningDrive}
          accessToken={accessToken}
          modalSetShow={setShow}
        />
        {selectedRootDrive && (
          <div className="mt-2">
            Selected root folder: <strong>{selectedRootDrive.name}</strong>
          </div>
        )}
      </div>
    </>
  );

  const renderStep2 = () => (
    <>
      <p>Select files and folders to import from <strong>{selectedRootDrive.name}</strong></p>
      <div className="mb-3">
        <GoogleDrivePicker
          onSelect={handleItemsSelect}
          disabled={assigningDrive}
          accessToken={accessToken}
          modalSetShow={setShow}
          recalibrate={true}
          driveId={selectedRootDrive.id}
        />
        {selectedItems && (
          <div className="mt-2">
            Selected items: <strong style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{selectedItems.map(drive => drive.name).join(",")}</strong>
          </div>
        )}
      </div>

      <Form.Group className="mb-3">
        <Form.Label className="fw-bold mb-2">Import Options</Form.Label>
        <div className="ms-2">
          <Form.Check
            type="checkbox"
            id="import-people"
            label="Import people (permissions)"
            checked={importOptions.people}
            onChange={() => handleImportOptionChange("people")}
            className="mb-2"
          />
          <Form.Check
            type="checkbox"
            id="import-folders"
            label="Import folders"
            checked={importOptions.folders}
            onChange={() => handleImportOptionChange("folders")}
            className="mb-2"
          />
          <Form.Check
            type="checkbox"
            id="import-files"
            label="Import files (requires folders)"
            checked={importOptions.files}
            disabled={!importOptions.folders}
            onChange={() => handleImportOptionChange("files")}
          />
        </div>
      </Form.Group>
    </>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      backdrop={assigningDrive ? "static" : true}
      keyboard={!assigningDrive}
      size="lg"
    >
      <Modal.Header closeButton={!assigningDrive}>
        <Modal.Title>
          {step === 1 ? "Select Root Folder" : "Select Items to Import"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {profile && (
          <>
            {assigningDrive ? (
              <>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Assigning Shared Drive to Group</p>
                <p>Please wait. This may take a few minutes depending on the size of the Shared Drive.</p>
              </>
            ) : (
              <>
                <p>This action is <strong style={{ color: "red" }}>NOT</strong> reversable.</p>
                {step === 1 ? renderStep1() : renderStep2()}
              </>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {step === 1 ? (
          <ThemeButton
            variant="outline-primary"
            onClick={handleNextStep}
            disabled={assigningDrive || !selectedRootDrive}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              Next
              <FaArrowRight />
            </div>
          </ThemeButton>
        ) : (
          <ThemeButton
            variant="outline-primary"
            onClick={handleAssignDrive}
            disabled={assigningDrive || !selectedItems}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FaAngleUp />
              Assign Drive
            </div>
          </ThemeButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ImportDriveToGroupModal;