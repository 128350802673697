import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import "./../styles/googleLoginButtonStyles.css";
import { Button } from "react-bootstrap";



const GoogleLoginButton = ({ validateUserFunction }) => {

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => validateUserFunction(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive.file",
    include_granted_scopes: false,
  });

  return (
    <>
      <button type="button" className="login-with-google-btn" onClick={() => login()} >
        Sign in with Google to access the app
      </button>
    </>
  );
};

export default GoogleLoginButton;


// Secondary Button Component
const SecondarySignInButton = ({ validateUserFunction }) => {

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => validateUserFunction(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive.file",
    include_granted_scopes: false,
  });
  return (
    <Button
      variant="btn btn-primary fst-italic"
      className="mt-5"
      style={{
        height: "50px",
        width: undefined,
        fontSize: undefined,
        padding: "10px",
        whiteSpace: "nowrap",
        border: "3px solid black",
        boxShadow: "2px 2px 8px rgba(0, 0, 1, 0.9)",
        textAlign: "center",
      }}
      onClick={() => {
        // Directly call the login function from GoogleLoginButton
        login();
      }}
    >
      Sign-in
    </Button>
  );
};

export { SecondarySignInButton };
