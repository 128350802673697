import "./styles/App.css";
import { useEffect, useState } from "react";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FolderPage from "./pages/folderPage.jsx";
import FormPage from "./pages/formPage";
import ProfilePage from "./pages/profilePage.jsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/homePage.jsx";
import LandingPage from "./pages/landingPage.jsx";
import NotFoundPage from "./pages/notFoundPage.jsx";
import ResponsePage from "./pages/responsePage.jsx";
import RegisterationPage from "./pages/registerationPage.jsx";
import DatascribeManagmentPage from "./pages/datascribeManagmentPage.jsx";
import PrivacyPolTermsAndCondPage from "./pages/privacyPolTermsAndCondPage.jsx";
import AnalyticsPage from "./pages/analyticsPage.jsx";
import ContactPage from "./pages/contactPage.jsx";
import LLMAssistantModal from "./components/llmAssistantModal.jsx";

const clientId =
  "406061929873-dfse5hcsap80gmq6m3e1mj9md1cot589.apps.googleusercontent.com";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage setUser={setUser} />} />
          <Route path="/home" element={<HomePage user={user} />} />
          <Route path='/profile' element={<ProfilePage user={user} />} />
          <Route path='/folders' element={<FolderPage user={user} />} />
          <Route path='/forms' element={<FormPage user={user} />} />
          <Route path='/responses' element={<ResponsePage/>} />
          <Route path='/register' element={<RegisterationPage user={setUser} />} />
          <Route path='/analysis' element={<AnalyticsPage user={setUser} />} />
          <Route path='/datascribeManagment' element={<DatascribeManagmentPage user={user} />} />
          <Route path='/privacy-policy' element={<PrivacyPolTermsAndCondPage isPrivacyPolicy={true} />} />
          <Route path='/terms-and-conditions' element={<PrivacyPolTermsAndCondPage isPrivacyPolicy={false} />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
        </Routes>
        <LLMAssistantModal />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
