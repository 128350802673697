import React, { useState, useRef } from "react";
import { Card, Collapse, Container, Row, Col, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeButton from "../components/themeButton";

const DataTableCards = ({ dataTables, setSelectedDataTableName }) => {
  const [openCardId, setOpenCardId] = useState(null);
  const scrollContainerRef = useRef(null);

  const toggleCard = (cardId) => {
    setOpenCardId(openCardId === cardId ? null : cardId);
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollContainerStyle = {
    overflowX: "auto",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
    padding: "20px"
  };

  const scrollButtonsStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  const buttonStyle = {
    margin: "0 5px",
    color: "#fff",
  };

  const cardStyle = {
    cursor: "pointer",
    transition: "transform 0.2s",
    backgroundColor: "#fff",
    borderColor: "#50011a",
    color: "#50011a",
    borderWidth: "2px",
    borderStyle: "solid",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    minWidth: "300px", // Ensure cards have a minimum width
  };

  const cardTitleStyle = {
    color: "#50011a",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  const themeButtonStyle = {
    marginRight: "5px",
    height: "50px",
    fontSize: "12px",
  };

  return (
    <Container className="mt-4" style={{ overflowY: "auto" }}>
      <div style={scrollContainerStyle} ref={scrollContainerRef}>
        <Row className="flex-nowrap">
          {dataTables.length === 0 && <p style={{ fontSize: "2rem", textAlign: "center" }}>No Data Tables found.</p>}

          {dataTables && dataTables.map((table) => (
            <Col key={table.table_name} md={4} className="mb-4">
              <Card onClick={() => toggleCard(table.table_name)}
                style={{
                  ...cardStyle, transform: openCardId === table.table_name ? "scale(1.05)" : "scale(1)",
                }}>
                <Card.Body>
                  <Card.Title style={cardTitleStyle}>{table.display_name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Created by: {table.first_name} {table.last_name}
                  </Card.Subtitle>
                  <Card.Text>{table.email}</Card.Text>

                  <Collapse in={openCardId === table.table_name}>
                    <div>
                      {table.table_type === "test_table" ? (
                        <Badge pill bg="danger" className="ms-2">Test Table</Badge>
                      ) : (
                        <Badge pill bg="primary" className="ms-2">Data Table</Badge>
                      )}
                      {table.visibility === "public" && (
                        <Badge pill bg="success" className="ms-2">Public</Badge>
                      )}
                      <hr />
                      <div className="d-flex justify-content-between">
                        <ThemeButton
                          variant="outline-primary"
                          style={themeButtonStyle}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedDataTableName(table.table_name);
                          }}>
                          Detailed View
                        </ThemeButton>
                      </div>
                      <div className="mt-2 text-muted small">
                        <p>Last Updated: {new Date(table.last_updated).toLocaleString()}</p>
                        <p>Created On: {new Date(table.created_on).toLocaleString()}</p>
                      </div>
                    </div>
                  </Collapse>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div style={scrollButtonsStyle}>
        <ThemeButton variant="outline-primary" onClick={scrollLeft} style={buttonStyle}>
        </ThemeButton>
        <ThemeButton variant="outline-primary" onClick={scrollRight} style={buttonStyle}>
        </ThemeButton>
      </div>
    </Container>
  );
};

export default DataTableCards;
