const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

export const postLLMAssistant = async (prompt) => {
  try {
    const response = await fetch(`${baseURL}/api/llmassistant/hf`,
      { 
        method: "POST", 
        credentials: "include", 
        headers: { "Content-Type": "application/json" }, 
        body: JSON.stringify({ prompt })
      }
    );
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const getLatestThread = async () => {
  try {
    const response = await fetch(`${baseURL}/api/llmassistant/latest_thread`,
      { 
        method: "GET", 
        credentials: "include", 
        headers: { "Content-Type": "application/json" }
      }
    );
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const postSaveThread = async (messages) => {
  try {
    const response = await fetch(`${baseURL}/api/llmassistant/save_thread`,
      { 
        method: "POST", 
        credentials: "include", 
        headers: { "Content-Type": "application/json" }, 
        body: JSON.stringify({ messages })
      }
    );
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const putSaveThread = async (messages) => {
  try {
    const response = await fetch(`${baseURL}/api/llmassistant/save_thread`,
      { 
        method: "PUT", 
        credentials: "include", 
        headers: { "Content-Type": "application/json" }, 
        body: JSON.stringify({ messages })
      }
    );
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};