import React, { useState, useEffect } from "react";
import background from "../assets/images/background4.png";
import ThemeNavbar from "../components/themeNavbar.jsx";
import { Link } from "react-router-dom";
import { getThisUser } from "../apis/userApis";
import { getAllTableSchemas, getAllDataTables } from "../apis/dataApi";
// import 'bootstrap/dist/css/bootstrap.min.css';
import TableSchemaCards from "../cards/tableSchemaCards";
import DataTableCards from "../cards/dataTablesCards";
import DataTablesDetailedView from "../components/dataTablesDetailedView";
import { Alert, Badge } from "react-bootstrap";

const AnalyticsPage = () => {
  const [profile, setProfile] = useState(null);
  const [serverError, setServerError] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [tableSchemas, setTableSchemas] = useState(null);
  const [dataTables, setDataTables] = useState(null);
  const [selectedDataTableName, setSelectedDataTableName] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    const userFromStorage = JSON.parse(localStorage.getItem("user"));
    if (!userFromStorage) {
      setServerError("401 Unauthorized Access");
    } else {
      const fetchdata = async () => {
        await fetchUserProfile();
        await fetchAllDataSchema();
        await fetchAllDataTables();
      };
      fetchdata();
      window.addEventListener("resize", handleResize);
    }
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getThisUser();
      if (response.status >= 200 && response.status < 300) {
        const user = response.data.user;
        setProfile(user);
        const user_role = user.clients.find(client => client.id === user.active_client_id)?.user_role;
        setUserRole(user_role);
      } else {
        handleFetchError(response.status);
        console.error(response.data.message);
        setServerErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      handleFetchError(503);
      setServerErrorMessage(error);
    }
  };

  const fetchAllDataSchema = () => {
    getAllTableSchemas()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setTableSchemas(response.data);
        } else {
          handleFetchError(response.status);
          setServerErrorMessage(response.data.message);

        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const fetchAllDataTables = () => {
    getAllDataTables()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setDataTables(response.data);
        } else {
          handleFetchError(response.status);
          setServerErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  const handleFetchError = (status) => {
    switch (status) {
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("Unknown Error");
        break;
    }
  };

  const pageStyle = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "repeat",
    backgroundSize: "150px 150px",
    minHeight: "100vh",
    paddingBottom: "25px",
  };

  return (
    <div style={pageStyle}>
      {profile && !serverError && !selectedDataTableName && (
        <div>
          <ThemeNavbar />
          {tableSchemas && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>Data Table Schemas</h1>
              </div>
              <TableSchemaCards tableSchemas={tableSchemas} userRole={userRole} loadDataTablesCallback={fetchAllDataTables} />
            </div>
          )}
          {dataTables && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>Data Tables</h1>
              </div>
              <DataTableCards dataTables={dataTables} setSelectedDataTableName={setSelectedDataTableName} />
            </div>
          )}
          <div>
            <div style={{
              display: "flex", flexDirection: "row", alignItems: "center",
              justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
              paddingTop: "20px",
            }}>
              <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>Data Analysis Tools</h1>
            </div>
            <Alert variant="warning" className="mt-4 d-flex align-items-center" style={{ marginLeft: "auto", marginRight: "auto", width: "85%" }} >
              <Badge bg="warning" text="dark" className="me-2">Work in Progress</Badge>
              This section is currently under development. New features and improvements coming soon!
            </Alert>
          </div>
        </div>
      )
      }
      {
        selectedDataTableName && (
          <div>
            <ThemeNavbar />
            <DataTablesDetailedView dataTableName={selectedDataTableName} setSelectedDataTableName={setSelectedDataTableName}
              fetchDataSchemas={fetchAllDataSchema} fetchDataTables={fetchAllDataTables} />
          </div>
        )
      }
      {
        serverError === "401 Unauthorized Access" && serverError && (
          <div className='site-container'>
            <header className="site-header">
              <div className="title-area">
                <h1 className="site-title">
                  <a href="https://birdshot.tamu.edu/">Birdshot Center</a>
                </h1>
              </div>
              <div className="widget-area header-widget-area">
                <a
                  id="site-logo"
                  href="https://birdshot.tamu.edu"
                  style={{ display: "block", maxWidth: "350px" }}
                >
                  <img
                    id="custom-header"
                    src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png"
                    alt="Texas A&M University College of Engineering"
                  />
                </a>
              </div>
            </header>
            <body className='site-body'>
              <h2 className='body-title'>Automated Google Web Form Creator and Manager</h2>
              <p className='login-response'>401 Unauthorized Access</p>
              <p>Please <Link to="/" className='link'>Login</Link> to continue.</p>
            </body>
          </div>
        )
      }
      {
        serverError && serverError !== "401 Unauthorized Access" && (
          <div>
            <ThemeNavbar />
            <div className='server-error-container'>
              {serverError === "403 Forbidden Access"
                ? (
                  <>
                    <p className='login-response'>
                      {serverError}
                    </p>
                    <p style={{ color: "#50011a" }}>
                      Cause of Error: {serverErrorMessage}
                    </p>
                    <p>
                      Logout of the website and login again, or ask your admin for permission
                    </p>
                  </>
                )
                : (
                  <>
                    <p className='login-response'>
                      {serverError}
                    </p>
                    <p style={{ color: "#50011a" }}>
                      Cause of Error: {serverErrorMessage}
                    </p>
                    <p>
                      Contact our development team at <a href='mailto:divyanshu@tamu.edu'>divyanshu@tamu.edu</a>, and <a href='mailto:attar.v@tamu.edu'>attar.v@tamu.edu</a>
                    </p>
                  </>
                )
              }
            </div>
          </div>
        )
      }
    </div >
  );
};

export default AnalyticsPage;