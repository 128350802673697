import React, { useEffect, useState } from "react";
import ThemeButton from "../components/themeButton";
import { FaFolderOpen } from "react-icons/fa6";
// import { gapi } from "gapi-script";

const GoogleDrivePicker = ({ modalSetShow, accessToken, onSelect, disabled = false, recalibrate = false, driveId = null }) => {
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const [, setPickerInitialized] = useState(false);

  useEffect(() => {
    // Load the Google Client Library
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => loadGoogleDriveApi();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const loadGoogleDriveApi = async () => {
    window.gapi.load("picker", () => {
      setPickerApiLoaded(true);
    });
  };

  const createPicker = () => {
    // Get the access token from your auth system
    // const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      alert("Please sign in first");
      return;
    }

    if (!pickerApiLoaded) {
      alert("Google Picker API not loaded");
      return;
    }

    let picker;

    if (recalibrate) {
      if (!driveId) {
        alert("Please assign a drive first");
        return;
      }
      picker = new window.google.picker.PickerBuilder()
        .addView(new window.google.picker.DocsView()
          .setIncludeFolders(true)
          .setFileIds(driveId)
          .setSelectFolderEnabled(true))
        .enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .setOAuthToken(accessToken)
        // .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        .setCallback(pickerCallback)
        .setAppId(process.env.REACT_APP_GOOGLE_CLIENT_ID)
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .build();
    }
    else {
      picker = new window.google.picker.PickerBuilder()
        .addView(new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS)
          .setOwnedByMe(true)
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setMimeTypes("application/vnd.google-apps.folder"))
        .addView(new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS)
          .setEnableTeamDrives(true)
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setMimeTypes("application/vnd.google-apps.folder"))
        .setOAuthToken(accessToken)
        // .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        .setCallback(pickerCallback)
        .setAppId(process.env.REACT_APP_GOOGLE_CLIENT_ID)
        .build();
    }


    picker.setVisible(true);
    setPickerInitialized(true);

    modalSetShow(false);
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      // const folderId = data.docs[0].id;
      const folder = recalibrate ? data.docs : data.docs[0];
      // console.log(folderId);

      // listSubfolders(folderId);

      // if (recalibrate && driveId !== folder.id) {
      //   alert("Select the original folder again");
      //   return;
      // }
      onSelect(folder);


      modalSetShow(true);

    }
  };

  // const listSubfolders = async (folderId) => {
  //   gapi.client.drive.files.list({
  //     "q": `"${folderId}" in parents and mimeType = "application/vnd.google-apps.folder"`,
  //     "pageSize": 10,
  //     "fields": "files(id, name)",
  //   }).then((response) => {
  //     console.log(response.result.files);
  //     if (response.result.files.length > 0) {
  //       response.result.files.forEach((folder) => {
  //         console.log(folder.id, folder.name);
  //       });
  //     } else {
  //       alert("No subfolders found");
  //     }
  //   }).catch((error) => {
  //     console.error("Error listing subfolders:", error);
  //   });
  // };

  return (
    <ThemeButton
      variant="outline-primary"
      onClick={createPicker}
      disabled={disabled || !pickerApiLoaded}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <FaFolderOpen />
        {recalibrate ? <div style={{ whiteSpace: "nowrap" }} >Give DataScribe.Cloud Access</div> : <div style={{ whiteSpace: "nowrap" }}>Select Root Folder</div>}
      </div>
    </ThemeButton>
  );
};

export default GoogleDrivePicker;