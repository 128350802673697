import React, { useState, useEffect } from "react";
import "./../styles/rootStyle.css";
import GoogleLoginButton from "./../components/googleLoginButton.jsx";
import { useNavigate } from "react-router-dom";
import { validateUser, initLogin, authorizeUserFromCookie } from "../apis/authorizationApis.js";
import LandingPageNavbar from "../components/landingPageNavbar.jsx";
import Footer from "../components/footer.jsx";
import Header from "../components/header.jsx";
import PrivacyPolTermsAndCondModal from "../modals/privacyPolTermsAndCondModal.jsx";

/**
 * Renders the landing page component.
 *
 * @param {Object} setUser - A function to set the user state.
 * @return {JSX.Element} The rendered landing page component.
 */
function LandingPage({ setUser }) {
  const [isUserValidated, setIsUserValidated] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [showPrivacyPolTermsAndCondModal, setShowPrivacyPolTermsAndCondModal] = useState(false);
  const [userForModal, setUserForModal] = useState(null);
  const [cookieForModal, setCookieForModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkActiveSession();
  }, []);

  const checkActiveSession = async () => {
    setIsAuthenticating(true);
    try {
      const response = await initLogin();
      if (response.status === 200) {
        setIsUserValidated(true);
        setServerError("");
        localStorage.setItem("user", JSON.stringify(response.data.userInfo));
        setUser(response.data.userInfo);
        navigate("/home");
      } else {
        if (response.status === 401) {
          setIsUserValidated(false);
          if (response.data.showTermsAndConditions || response.data.showPrivacyPolicy) {
            setServerError("401 Unauthorized.\n Server Error: " + response.data.message);
          }
          setUserForModal(response.data.user);
          console.log(response.data.showTermsAndConditions, response.data.showPrivacyPolicy);
          setShowPrivacyPolTermsAndCondModal(response.data.showTermsAndConditions || response.data.showPrivacyPolicy);
        }
        setIsAuthenticating(false);
      }
    } catch (error) {
      setIsAuthenticating(false);
      console.error("Error checking active session:", error);
    }
  };

  /**
     * Handles the Google login process by validating the user and fetching user information.
     *
     * @param {string} USER_GOOGLE_AUTH_CODE - The Google authirization code sent by the user.
     * @return {Promise<void>} - A promise that resolves when the login process is complete.
     */
  const handleGoogleLogin = async (USER_GOOGLE_AUTH_CODE) => {
    console.log(USER_GOOGLE_AUTH_CODE);
    const response = await validateUser(USER_GOOGLE_AUTH_CODE);
    console.log(response.status);
    switch (response.status) {
      case 200:
        setIsUserValidated(true);
        setServerError("");
        localStorage.setItem("user", JSON.stringify(response.data.userInfo));
        setUser(response.data.userInfo);
        navigate("/home");
        break;
      case 401:
        setIsUserValidated(false);
        if (response.data.showTermsAndConditions || response.data.showPrivacyPolicy) {
          setServerError("401 Unauthorized.\n Server Error: " + response.data.message);
        }
        setUserForModal(response.data.user);
        setCookieForModal(response.data.cookie);
        console.log(response.data.authCode);
        setShowPrivacyPolTermsAndCondModal(response.data.showTermsAndConditions || response.data.showPrivacyPolicy);
        break;
      case 403:
        setIsUserValidated(false);
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setIsUserValidated(false);
        setServerError("404 Not Found");
        break;
      case 429:
        setIsUserValidated(false);
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setIsUserValidated(false);
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setIsUserValidated(false);
        setServerError("503 Service Unavailable");
        break;
      default:
        setIsUserValidated(false);
        setServerError("Unexpected Server Error");
    }
  };

  return (
    <>
      <div className='site-container'>
        <LandingPageNavbar />
        <Header googleValidatorFunction={handleGoogleLogin} />

        <body className='site-body' style={{ backgroundColor: "transparent" }}>
          {isAuthenticating ? (
            <div className="signing-in-modal">
              <p>Signing you in...</p>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "20px", marginBottom: "20px" }}>
              <div className="container py-4 bg-white rounded shadow-sm">

                <div className="py-1 px-1 bg-white rounded text-center">
                  <h2 className="display-4 fw-bold text-primary">Welcome to Datascribe Studio</h2>
                  <p className="lead text-muted">
                    AI-powered research data management designed to simplify workflows and accelerate discoveries.
                  </p>
                  <hr className="my-4 mx-auto" />
                </div>

                <p className="text-muted fs-4 text-center">
                  <i className="bi bi-folder-fill text-primary fs-2 me-2"></i>
                  <strong>DataScribe.cloud</strong> simplifies research data management by enabling effortless
                  <span className="text-primary">Google Drive folder structuring</span>,
                  <span className="text-success">collaborative form creation</span>,
                  and <span className="text-warning"> AI-powered insights</span> for researchers and businesses.
                </p>


                {/* 🔹 Large Technology Icons Section */}
                <div className="d-flex justify-content-center flex-wrap gap-5 mt-4">
                  <div className="text-center">
                    <i className="bi bi-google text-danger fs-1 fs-display-2"></i>
                    <p className="mt-2 fw-bold text-muted">Google</p>
                  </div>
                  <div className="text-center">
                    <i className="bi bi-bootstrap text-primary fs-1 fs-display-2"></i>
                    <p className="mt-2 fw-bold text-muted">Bootstrap</p>
                  </div>
                  <div className="text-center">
                    <i className="bi bi-braces text-info fs-1 fs-display-2"></i>
                    <p className="mt-2 fw-bold text-muted">React</p>
                  </div>
                  <div className="text-center">
                    <i className="bi bi-cloud text-primary fs-1 fs-display-2"></i>
                    <p className="mt-2 fw-bold text-muted">Digital Ocean</p>
                  </div>
                  <div className="text-center">
                    <i className="bi bi-database text-success fs-1 fs-display-2"></i>
                    <p className="mt-2 fw-bold text-muted">SQL Database</p>
                  </div>
                </div>
                <hr className="my-4 mx-auto" />

                {/* 🔹 Full-Width Sign-In Container */}
                <div className="container-fluid d-flex justify-content-center mt-2">
                  <div className="row bg-white shadow-lg rounded p-1 w-100" style={{ maxWidth: "1200px" }}>

                    {/* 🔹 Left Column - Cartoon Illustration */}
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                      <img
                        src="https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7963.jpg?semt=ais_hybrid"
                        alt="Login Illustration"
                        className="img-fluid"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </div>

                    {/* 🔹 Right Column - Sign-In Form */}
                    <div className="col-md-6 d-flex flex-column justify-content-center text-center">

                      {/* 🔹 Sign-In Title */}
                      <h4 className="fw-bold text-primary">
                        <i className="bi bi-box-arrow-in-right me-2"></i> Sign In to Datascribe
                      </h4>
                      <p className="text-muted fs-5">
                        Access AI-powered research tools and streamline your data management experience.
                      </p>

                      {/* 🔹 Google Login Button */}
                      <div className="mt-3">
                        <GoogleLoginButton validateUserFunction={handleGoogleLogin} />
                      </div>

                      {/* 🔹 New User Registration */}
                      <p className="mt-3 text-secondary">
                        <i className="bi bi-person-plus-fill text-success me-2"></i>
                        New here? <a className="fw-bold text-primary" href="/register">Create an account</a>
                      </p>

                      {/* 🔹 Error Handling */}
                      {isUserValidated === false && serverError && (
                        <div className="alert alert-danger mt-4 text-center shadow-sm">
                          <p className="fw-bold">{serverError}</p>
                          {serverError === "403 Forbidden Access" && (
                            <>
                              <p className="text-danger">
                                Please register your account with <strong>Datascribe.cloud</strong> before signing in.
                              </p>
                              <p>If you have already registered and the issue persists, please contact our support team.</p>
                            </>
                          )}
                          <p className="mt-3">
                            <i className="bi bi-envelope-fill text-dark me-2"></i>
                            Contact our development team at
                            <a href="mailto:divyanshu@tamu.edu" className="text-primary ms-1">divyanshu@tamu.edu</a>,
                            <a href="mailto:attari.v@tamu.edu" className="text-primary ms-1">attari.v@tamu.edu</a>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <hr className="my-4 mx-auto" />


                {/* 🔹 Vision Section */}
                <div className="mt-5 text-center">
                  <h4 className="fw-bold text-primary">
                    <i className="bi bi-lightbulb-fill me-2"></i> Our Vision
                  </h4>
                  <p className="text-muted fs-5">
                    At <strong>Datascribe.cloud</strong>, we empower researchers and small businesses to unlock the full potential of their data through accessible, affordable, and intelligent cloud-based solutions.
                  </p>

                  {/* 🔹 Vision List */}
                  <ul className="list-unstyled mt-2 text-start d-inline-block">
                    <li className="mb-0">
                      <i className="bi bi-globe-americas text-info fs-3 me-2"></i>
                      <strong>Global Accessibility:</strong> Making cutting-edge data management available to researchers and businesses worldwide.
                    </li>
                    <li className="mb-0">
                      <i className="bi bi-graph-up-arrow text-success fs-3 me-2"></i>
                      <strong>Data-Driven Innovation:</strong> Empowering users with intelligent insights, automated workflows, and AI-driven analytics.
                    </li>
                    <li className="mb-0">
                      <i className="bi bi-people-fill text-warning fs-3 me-2"></i>
                      <strong>Collaboration & Growth:</strong> Fostering a connected research environment that accelerates discoveries and innovation.
                    </li>
                    <li className="mb-0">
                      <i className="bi bi-lightning-charge text-danger fs-3 me-2"></i>
                      <strong>Efficiency & Simplicity:</strong> Streamlining research workflows with intuitive, powerful tools for data organization and analysis.
                    </li>
                  </ul>
                </div>

                {/* 🔹 Mission Section */}
                <div className="mt-5 text-center">
                  <h4 className="fw-bold text-primary">
                    <i className="bi bi-gear-wide-connected me-2"></i> Our Mission
                  </h4>
                  <p className="text-muted fs-5">
                    At <strong>Datascribe.cloud</strong>, our mission is to revolutionize research through AI-driven, scalable, and secure data solutions.
                  </p>

                  {/* 🔹 Mission List */}
                  <ul className="list-unstyled mt-2 text-start d-inline-block">
                    <li className="mb-0">
                      <i className="bi bi-robot text-secondary fs-3 me-2"></i>
                      <strong>AI-Powered Insights:</strong> We leverage artificial intelligence to streamline data analysis and uncover hidden patterns.
                    </li>
                    <li className="mb-0">
                      <i className="bi bi-database-lock text-danger fs-3 me-2"></i>
                      <strong>Seamless Data Management:</strong> Our platform organizes and processes research data with intelligent automation.
                    </li>
                    <li className="mb-0">
                      <i className="bi bi-people-fill text-warning fs-3 me-2"></i>
                      <strong>Real-Time Collaboration:</strong> Teams can work together effortlessly, ensuring data is shared securely and efficiently.
                    </li>
                    <li className="mb-0">
                      <i className="bi bi-shield-lock text-primary fs-3 me-2"></i>
                      <strong>Security & Compliance:</strong> We prioritize data protection with industry-leading security measures.
                    </li>
                  </ul>
                </div>


              </div>

              <h5 className='body-title' style={{ marginTop: "10px" }}>Sign In to Datascribe</h5>
              <GoogleLoginButton
                validateUserFunction={handleGoogleLogin}
              ></GoogleLoginButton>
              <div>
                new users start <a className="link" href="/register">here </a>
              </div>
              {isUserValidated === false && serverError && (
                <div className="server-error-container">
                  <p className="login-response">{serverError}</p>
                  {serverError === "403 Forbidden Access" && (
                    <>
                      <p style={{ color: "#50011a" }}>
                        Please first register your account with Datascribe.cloud
                      </p>
                      <p>
                        If you have already registered, and the issue persists.
                      </p>
                    </>
                  )}
                  <p>
                    Contact our development team at{" "}
                    <a href="mailto:divyanshu@tamu.edu">divyanshu@tamu.edu</a>, and{" "}
                    <a href="mailto:attari.v@tamu.edu">attari.v@tamu.edu</a>
                  </p>
                </div>
              )}
            </div>
          )}
        </body>
        <PrivacyPolTermsAndCondModal
          showModal={showPrivacyPolTermsAndCondModal}
          setShowModal={setShowPrivacyPolTermsAndCondModal}
          userEmail={userForModal}
          onClose={async () => {
            if (cookieForModal) {
              const response = await authorizeUserFromCookie(cookieForModal);
              if (response.status !== 200) {
                console.error("Error authorizing user from cookie:", response);
                setServerError(response.status + " " + response.data.message);
                return;
              }
              window.location.reload();
            }
          }}
        />
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;
