// header.jsx

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { SecondarySignInButton } from "./googleLoginButton.jsx";
import "../styles/Header.css";

const Header = ({googleValidatorFunction}) => {
  const location = useLocation(); // Get the current route
  const isHomePage = location.pathname === "/"; // LandingPage, homepage, etc.

  // Videos for the homepage

  const homepageVideos = [
    "/videos/DataFlow1.mp4",
    "/videos/ScientistInLab.mp4",
    "/videos/DataFlow2.mp4",
  ];

  // Images for other pages
  const otherPageImages = [
    "./images/MaterialsScientistInLab.jpg",
    "./images/DataStreaming.jpeg",
  ];

  // Static images for small screens
  const smallScreenImages = ["./images/MaterialsScientistInLab.jpg"];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 168);

  // Handle screen resize and item rotation
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 168);
    };

    window.addEventListener("resize", handleResize);

    const items = isHomePage ? homepageVideos : otherPageImages;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000); // Change every 5 seconds

    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    };
  }, [isHomePage, homepageVideos.length, otherPageImages.length]);

  // Determine the current background item (video or image)
  const currentItem = isSmallScreen
    ? smallScreenImages[currentIndex % smallScreenImages.length]
    : isHomePage
      ? homepageVideos[currentIndex]
      : otherPageImages[currentIndex];

  console.log(currentItem);

  // Check if text should be hidden based on the current item
  const noTextImages = ["../assets/images/MaterialsScientistInLab.jpg"];
  const shouldShowText = !noTextImages.includes(currentItem);

  return (
    <main className="container-fluid p-0">
      <div
        className="p-1 p-md-5 text-light bg-body-secondary"
        style={{
          position: "relative",
          height: isSmallScreen ? "400px" : "480px",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* Video or Image Background */}
        {isHomePage && !isSmallScreen ? (
          <video
            key={currentItem}
            src={currentItem}
            autoPlay
            muted
            loop
            playsInline
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the video fills the entire container
              pointerEvents: "none", // Prevent video from blocking interactions
            }}
          ></video>
        ) : (
          <img
            src={currentItem}
            alt="Background"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image fills the entire container
            }}
          />
        )}

        {/* Foreground Content */}
        <Container
          style={{
            position: "relative", // Ensure content stays on top
            zIndex: "1", // Higher z-index than background
          }}
        >
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="px-2 my-4 text-left">
              {shouldShowText && (
                <>
                  <h3
                    className={`fw-bold fst-italic rounded text-shadow yeserva-font ${isSmallScreen ? "fs-3" : "display-4"}`}
                    style={{
                      fontSize: isSmallScreen ? "1.5rem" : "2.5rem", // Adjust font size here
                      textAlign: isSmallScreen ? "center" : undefined,
                      fontWeight: isSmallScreen ? "600" : undefined,
                      WebkitFontSmoothing: "antialiased",
                      MozOsxFontSmoothing: "grayscale",
                    }}
                  >
                    DataScribe: Organize, Analyze, and Accelerate Your Work!
                  </h3>
                  <h3
                    className={`text-shadow kavoon-font ${isSmallScreen ? "fs-5" : "fs-2"}`}
                    style={{
                      fontSize: isSmallScreen ? "1.2rem" : undefined,
                      textAlign: isSmallScreen ? "center" : undefined,
                      WebkitFontSmoothing: "antialiased",
                      MozOsxFontSmoothing: "grayscale",
                    }}
                  >
                    <span className="top-text">AI-Powered Research Data Management - Simplified!</span>
                  </h3>
                </>
              )}
            </Col>

            {!isSmallScreen && (
              <div className="image-selectors p-1">
                {(isHomePage ? homepageVideos : otherPageImages).map((_, index) => (
                  <button
                    key={index}
                    className={`image-selector ${index === currentIndex ? "active" : ""}`}
                    onClick={() => setCurrentIndex(index)}
                  ></button>
                ))}
              </div>
            )}


            <div className="text-start">
              <SecondarySignInButton validateUserFunction={googleValidatorFunction} />
            </div>

          </Row>
        </Container>
      </div>
    </main>
  );
};

export default Header;