import React, { useEffect, useState } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import { matchPath, useLocation } from "react-router-dom";
import { LuSend, LuX, LuBotMessageSquare, LuBot, LuMenu } from "react-icons/lu";
import "../styles/llmAssistantModalStyles.css";
import { postLLMAssistant, getLatestThread, postSaveThread, putSaveThread } from "../apis/llmassistantApis";

const starterMessage = [
  {
    role: "system",
    text: "You are currently using the microsoft/Phi-3.5-mini-instruct model. How may I assist you?",
    saved: true,
  },
];

const LLMAssistantModal = () => {
  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState(() => {
    const storageMessage = sessionStorage.getItem("llm_assistant_messages");
    if (storageMessage) {
      return JSON.parse(storageMessage);
    }
    return starterMessage;
  });

  const toggleShow = () => {
    setShow(!show);
  };

  const location = useLocation();
  const excludedRoutes = ["/"];
  const isExcluded = excludedRoutes.some((route) => {
    return matchPath({ path: route, end: true }, location.pathname);
  });
  // console.log(location.pathname);

  if (isExcluded) {
    return null;
  }

  return (
    <div className="chat-container">
      {show ? (
        <LLMModalCard
          show={show}
          toggleShow={toggleShow}
          messages={messages}
          setMessages={setMessages}
        />
      ) : (
        <button
          style={{
            position: "fixed",
            bottom: "20px",
            right: "50px",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            border: "4px solid rgb(80, 1, 26)",
            boxShadow: "inset 0 0 5px rgba(80, 1, 26)",
            background: "white",
          }}
          onClick={toggleShow}
        >
          <LuBotMessageSquare size="30" />
        </button>
      )}
    </div>
  );
};

const LLMModalCard = ({ show, toggleShow, messages, setMessages }) => {
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const addMessage = (messageRole, messageText, model_id = null) => {
    const newMessage = { role: messageRole, text: messageText, saved: false, model_id, time: new Date().toISOString() };
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      sessionStorage.setItem("llm_assistant_messages", JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    console.log(messages);
  };

  const sendMessage = async (message) => {
    try {
      addMessage("system", "Loading...");
      const response = await postLLMAssistant(message);
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.text !== "Loading..."));
      if (response.status !== 200) {
        addMessage("system", "I'm sorry, I couldn't understand that. Try again.", -1);
        return;
      }

      addMessage("system", response.data.inference, response.data.model_id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim()) {
      addMessage("user", inputText.trim());

      await sendMessage(inputText.trim());

      setInputText(""); // Clear input
      const inputElement = document.querySelector("input[name='llm_input']");
      if (inputElement) {
        inputElement.value = "";
      }
    }
  };

  const scrollToBottom = () => {
    const messagesContainer = document.querySelector(".modal-messages");
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-container">
      <div className="modal-header">
        < LLMAssistantMenu 
          messages={messages} 
          setMessages={setMessages} />
        <div className="modal-title text-center">
          <LuBot size={25} />
          Material Assistant
        </div>
        <button onClick={toggleShow} className="modal-close" title="Close">
          <LuX size={25} />
        </button>
      </div>
      <div className="modal-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            {msg.text}
          </div>
        ))}
      </div>

      <Form className="p-2 border-top border-black border-2 w-full d-flex gap-2">
        <Form.Control
          type="text"
          placeholder="Type your message here..."
          name="llm_input"
          onChange={(e) => setInputText(e.target.value)}
          className="border-black"
          required
        />
        <Button 
          variant="primary"  
          type="submit" 
          onClick={handleSubmit} 
          title="Send"
          style={{ 
            background: "rgb(80,26,1)",
            border: "rgb(80,26,1)",
          }}>
          <LuSend />
          <span className="visually-hidden">Send</span>
        </Button>
      </Form>
    </div>
  );
};

const LLMAssistantMenu = ({ messages, setMessages }) => {
  const handleLoadMessages = async () => {
    // TODO: Fetch messages from database
    const response = await getLatestThread();
    console.log(response);
    // If no messages from database, start with a system message
    if (!response || response.status !== 200) {
      setMessages(starterMessage);
      sessionStorage.setItem("llm_assistant_is_pulled", false);
      window.alert("No saved session found");
      return;
    }

    const messages = response.data.messages.map((msg) => ({ 
      role: msg.role_name,
      text: msg.message_text, 
      time: msg.message_time, 
      model_id: msg.model_id, 
      saved: true 
    }));
    // update the state with the messages
    setMessages(messages);
    // store the messages in session storage
    sessionStorage.setItem("llm_assistant_messages", JSON.stringify(messages));
    sessionStorage.setItem("llm_assistant_is_pulled", true);
    window.alert("Last session loaded successfully");
  };
  
  const handleSaveMessages = async () => {
    const unsavedMessages = messages.filter((msg) => !msg.saved);
    if(unsavedMessages.length === 0) {
      return;
    }

    // check if messages were pulled from the database
    console.log(sessionStorage.getItem("llm_assistant_is_pulled"));
    if (sessionStorage.getItem("llm_assistant_is_pulled") === "true") {
      console.log("PUT Messages");
      const response = await putSaveThread(unsavedMessages);
      if (response.status !== 200) {
        console.error("Failed to save messages");
        return;
      }
    } else {
      console.log("POST Messages");
      const response = await postSaveThread(unsavedMessages);
      if (response.status !== 200) {
        console.error("Failed to save messages");
        return;
      }
    }

    // successful: update the messages to saved
    const updatedMessages = messages.map((msg) => (
      msg.saved ? msg : { ...msg, saved: true }
    ));
    setMessages(updatedMessages);
    sessionStorage.setItem("llm_assistant_messages", JSON.stringify(updatedMessages));

    window.alert("Messages saved successfully");
    return;    
  };

  const handleClearMessages = () => {
    setMessages(starterMessage);

    sessionStorage.removeItem("llm_assistant_messages");
    sessionStorage.setItem("llm_assistant_is_pulled", false);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" style={{ background: "#f0f0f0", color: "black", border: "0", padding: "4px" }} id="dropdown-basic">
        <LuMenu size={25} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={handleLoadMessages} title="Load Saved Session">
          Load Saved Session
        </Dropdown.Item>
        <Dropdown.Item onClick={handleSaveMessages} title="Save Messages">
          Save Messages
        </Dropdown.Item>
        <Dropdown.Item onClick={handleClearMessages} title="Clear Messages">
          {/* <LuTrash2 size={20} /> */}
          Clear Messages
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LLMAssistantModal;
